<template>
    <div @click="hideFollowModal">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场中心</a-breadcrumb-item>
            <a-breadcrumb-item>表单数据({{detail.survey_name}})</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>搜索关键字</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.keyword" allowClear placeholder="请输入搜索关键字" style="width: 160px"/>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>提交时间</span>
                        </template>
                        <a-range-picker
                        style="width: 240px"
                        :ranges="rangesData"
                        v-model='searchDatas.add'
                        allowClear
                        @change="(val,time)=>handleTime(val,time,'add')" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                    </a-form>
                </div>
                <div class="buttons">
                    <a-form layout='inline'>
                      <a-form-item>
                        <a-button @click="toZip" icon="export" :loading="exportLoading" type="dashed">导出</a-button>
                      </a-form-item>
                      <a-form-item>
                        <a-button type="primary" @click="back()" icon="rollback">返回</a-button>
                      </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="survey_stat_id"
                :columns="columns" :dataSource="list" :scroll="{ x: wWidth }" @change="handleChange">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="images" slot-scope="text, record">
                        <LImg v-for="(image, index) of text" :key="index" :src="image" style="width: 150px; height: 75px; margin: 0 5px 5px 0"/>
                    </template>
                    <template slot="image" slot-scope="text, record">
                        <LImg :preview="0" v-for="(image, index) of text" :key="index"  :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
                    </template>
                    <template slot="related" slot-scope="text,record">
                        <a v-if="record.customer_detail" @click="customerClick($event,record.customer_detail)">{{record.customer_detail.customer_name}}</a>
                        <a v-else-if="record.leads_detail" @click="leadClick($event,record.leads_detail)">{{record.leads_detail.clue_name}}</a>
                        <span v-else>
                            -
                        </span>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <followModal :authType="['crm','leads']" :item="followData" :leadsId=leadsId :isTab='isTab' v-if="followVisible"/>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import followModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:'surveyDetail',
        mixins: [ tableMixins, ranges ],
        components: {
            followModal,
        },
        data() {
            return {
                isTab:'0',
                followVisible: false,
                followData:{},
                leadsId:"",
                wWidth:1500,
                columns: [],
                list:[],
                detail:{},
                loading:false,
                exportLoading:false,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchDatas:{}
            }
        },
        created(){
            this.getDetail();
        },
        methods: {
            async getDetail(){
                let res = await this.$store.dispatch('surveyCenterDetailAction', {data: { survey_id: this.$route.params.id }})
                this.detail = res.data.survey
            },
            async getList() {
                this.loading = true
                this.searchParams.survey_id = this.$route.params.id
                let res = await this.$store.dispatch('surveyCenterFormdataAction',this.searchParams)
                let columns = [{ title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'}]
                if(res._data.columns.length > 10){
                    this.wWidth = 1500 + (res._data.columns.length - 10) * 100
                }
                res._data.columns.forEach((item,index)=>{
                    let obj = {}
                    if(item.column_format == 'image'){
                        if(item.column_type == 'signature'){
                            //,width:'150px',align:'center'
                            obj = { title: `${index+1}. ${item.column_title}`, dataIndex: item.column_id, key: item.column_id,scopedSlots: { customRender: 'images' }}
                        }else{
                            obj = { title: `${index+1}. ${item.column_title}`, dataIndex: item.column_id, key: item.column_id,scopedSlots: { customRender: 'image' }}
                        }
                    }else{
                        obj = { title: `${index+1}. ${item.column_title}`, dataIndex: item.column_id, key: item.column_id}
                    }
                    columns.push(obj)
                })
                
                columns.push({title: '招生关联', dataIndex: 'related', align:'center' , key: 'related' , scopedSlots: { customRender: 'related' }})
                columns.push({title: '完成地点', dataIndex: 'survey_stat_ip', key: 'survey_stat_ip'})
                // columns.push({title: '完成时间', dataIndex: 'survey_stat_finish_at', key: 'survey_stat_finish_at',sorter:()=>{}})
                columns.push({title: '完成时间', dataIndex: 'survey_stat_finish_at', key: 'survey_stat_finish_at'})
                this.columns = columns
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            back(){
                this.$router.go(-1)
            },
            hideFollowModal() {
                this.followVisible = false
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                }else{
                    this.searchParams['sort'] = ''
                }
                this.getList()
            },
            customerClick(e,customer_detail){
                e && e.stopPropagation()
                this.isTab = '1'
                this.followData = customer_detail
                console.log(customer_detail)
                this.leadsId = customer_detail.task_id
                this.followVisible = true
                return 
            },
            leadClick(e,leads_detail){
                e && e.stopPropagation()
                this.isTab = '0'
                this.followData = leads_detail
                this.leadsId = leads_detail.task_id
                this.followVisible = true
                return 
            },
            async toZip(){
                this.exportLoading = true
                await this.$store.dispatch('surveyCenterExportAction',this.searchParams)
                .then(res=>{
                    this.exportLoading = false
                    window.open(res.data.export_path)
                })
                .catch(err=>{
                    this.exportLoading = false
                })
                
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>